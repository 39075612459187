import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/styles';
import "../assets/css/shop.css";

const useStyles = makeStyles({
    root: {
      width: 1000,
    //   height: 850,
    },
  });

const ShopPage = () => {

//     const classes = useStyles();
//     const data = useStaticQuery(query);
    
//     console.log(data);

    return (
        <Layout>        
            {/* <div className="uk-section">
                <div className="uk-container uk-container-large">
                    <div className="shop-container">
                        {data.strapiShop.squarespace_products.map((item) => (
                            <div key={item.ProductName} dangerouslySetInnerHTML={{ __html: item.ItemBuyHTML}}></div>
                            
                        ))}
                    </div>
                </div>
            </div> */}
        </Layout>
    );
 }

// const query = graphql`
//     query {   
//         strapiShop {
//             WelcomeMessage
//             squarespace_products {
//                 Available
//                 ItemBuyHTML
//                 ProductName
//             }
//         }
//     }
// `;

export default ShopPage;